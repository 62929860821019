/* src/components/Calendar/Calendar.css */
.event-span {
  position: relative;
  background-color: rgba(0, 123, 255, 0.5); /* Adjust color as needed */
  /* border-radius: 4px; */
  padding: 2px 4px;
  margin: 2px 0;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  color: white;
  font-size: 0.75rem;
  overflow: hidden;
  height: 25px;
}

.event-span::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 123, 255, 0.5); /* Adjust color as needed */
  z-index: -1;
}

.event-span.start::before {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.event-span.end::before {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.event-placeholder {
  height: 1.5rem; /* Adjust height as needed */
  margin: 2px 0;
}
